img {
  width: 100% !important;
  height: 100% !important;
}



@font-face {
  font-family: Silent Caroline;
  src: url("../font/Silent\ Caroline.otf") format("opentype");
}

@font-face {
  font-family: Saling Cinta;
  src: url("../font/Saling\ Cinta.otf") format("opentype");
}

$main-green : #969D79;
$main-pink : #DBB9B4;

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

section.top {
  background-color: $main-green;
  text-align: right;
}

section.top p {
  margin-bottom: 0px;
  text-align: right;
}

section.top a {
  font-family: Cormorant;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  color: #FFFFFF;
}

.logo img {
  margin: auto;
}

.menu nav li a {
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #5A5A5A;
  text-transform: none;
  position: relative;
}

.menu nav li a:hover {
  color: $main-pink !important;
}

.menu nav li.active a {
  color: $main-pink !important;
}

section.slider {
  position: relative;
  padding: 100px 0px;
  overflow: hidden;
}

.hover1 {
  height: 378px;
  width: 200px;
  background-image: url(../img/hover1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(-14.66deg);
  position: absolute;
  right: 10px;
  top: 0;
}

.hover2 {
  height: 240px;
  width: 208px;
  background-image: url(../img/hover2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  bottom: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

section.slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 29%;
  height: 100%;
  background-color: $main-green;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.box1 {
  height: 500px;
  border-radius: 400px 400px 0px 0px;
  overflow: hidden;
  position: relative;
}

.hover3 {
  width: 123px;
  height: 189px;
  transform: rotate(37.78deg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/hover3.png);
  position: absolute;
  top: 0;
  right: 0;
}

.box1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section.slider h1 {
  font-family: Saling Cinta;
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 58px;
  color: $main-green;

  @media only screen and (max-width: 768px) {
    font-size: 60px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  @media only screen and (max-width: 600px) {
    font-size: 50px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.3;
  }
}

section.slider h2 {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 81px;
  color: #5A5A5A;

  @media only screen and (max-width: 768px) {
    line-height: 1.3;
    margin-top: 20px;
  }
}

section p,
section li {
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #5A5A5A;
}

.button {
  font-family: Playfair Display;
  border: unset;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  color: #FFFFFF;
  background: $main-pink;
  box-shadow: 0px 8px 12px rgba(90, 90, 90, 0.25);
  padding: 15px 30px;

  @media only screen and (max-width: 768px) {
    padding: 10px 15px;
    font-size: 18px;
    line-height: 18px;
  }

  @media only screen and (max-width: 600px) {
    padding: 5px 15px;
    font-size: 14px;
    line-height: 14px;
  }
}

.button:hover {
  cursor: pointer;
  background: $main-green;
  color: #fff;
  text-decoration: unset;
}

section.one {
  padding: 70px 0px;

  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }
}

section h2 {
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #DBB9B4;
}

section h3 {
  font-family: Saling Cinta;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  color: #5A5A5A;
}

.justify-center {
  text-align: justify;
  text-align-last: center;
}

.bg1 {
  background-image: url(../img/img2.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 20px;
}

// .box-img1
// {
//   height: 500px;
// }
.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scale {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.height600 {
  height: 600px;
}

.calque1 {
  background-image: url(../img/calque1.svg);
  background-size: contain;
  width: 130px;
  height: 300px;
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: -1;
}

.two {
  padding: 100px 0px;
}

.calque2 {
  background-image: url(../img/calque2.svg);
  width: 130px;
  height: 230px;
  position: absolute;
  bottom: -100px;
  right: 30px;
  z-index: 999;
  background-repeat: no-repeat;
}

.bg-green {
  background-color: $main-green;
  position: relative;
  font-family: Cormorant;
}

.noPaddingBottom {
  padding-bottom: 0px;
}

.bg-green h2 {
  color: #fff !important;
}

.bg-green h3 {
  color: #fff !important;
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}

.bg-green p,
.bg-green li {
  color: #fff !important;
}

section .h2,
footer .h2 {
  font-family: Saling Cinta;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  color: #5A5A5A;
}

div.pad20 {
  padding: 20px 0px;
}

div.pad30 {
  padding: 30px 0px;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
}

.box-img2 {
  height: 300px;
  border-radius: 400px 400px 0px 0px;
  overflow: hidden;
  margin-bottom: 20px;
}

.divider {
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid $main-pink;
}

.bg2 {
  background: #FDFDFD;
  box-shadow: 0px 0px 33px rgba(150, 157, 121, 0.25);
  position: relative;
}

.h22 {
  font-family: Saling Cinta;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  color: #5A5A5A;
}

.box-img3 {
  height: 550px;
  position: relative;
}

.button1 {
  font-family: Playfair Display;
  font-style: normal;
  border: unset;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  color: #FFFFFF;
  background: $main-green;
  box-shadow: 0px 8px 12px rgba(90, 90, 90, 0.25);
  padding: 15px 30px;
}

.button1:hover {
  cursor: pointer;
  background: $main-pink;
  color: #fff;
  text-decoration: unset;
}

section .coloredPink,
footer .coloredPink {
  color: $main-pink;
}

section .coloredGreen,
footer .coloredGreen {
  color: $main-green !important;
}

section.bg3 {
  background-image: url(../../assets/img/calque3.svg);
  background-size: 20%;
  background-position: right 1% top 30%;
  background-repeat: no-repeat;
}

.bg-gray {
  background: #E5E5E5;
}

.box-img4 {
  height: 400px;
  border-radius: 400px 400px 0px 0px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

.calque4 {
  background-image: url(../img/calque4.svg);
  width: 72.66px;
  height: 105.76px;
  position: absolute;
  top: -20px;
  right: -10px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.relativePos {
  position: relative;
}

.box-img5 {
  height: 200px;
}

.border-right1 {
  position: relative;
}

.border-right1::after {
  content: "";
  position: absolute;
  right: 5px;
  top: 0;
  height: 60%;
  width: 2px;
  background-color: $main-pink;
}

.border-left1::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 60%;
  width: 2px;
  background-color: $main-pink;
}

section.flowers {
  box-shadow: 0px 8px 12px rgba(150, 157, 121, 0.25);
  height: 550px;
}

section.flowers::before {
  content: "";
  position: absolute;
  background-image: url(../img/bg-green1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  left: 10px;
  bottom: -20px;
  transform: rotate(-4.41deg);
  width: 200px;
  height: 363.02px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

section.flowers::after {
  content: "";
  position: absolute;
  background-image: url(../img/bg-green2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  right: 10px;
  bottom: -50px;
  transform: rotate(-4.41deg);
  width: 200px;
  height: 363.02px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.box-white {
  background: #FDFDFD;
  box-shadow: 0px 0px 33px rgba(150, 157, 121, 0.25);
  border-radius: 400px 400px 0px 0px;
  padding: 20px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 40px;
  }
}

.icon {
  margin: auto;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.moveTop {
  margin-top: -300px;
}

.h1 {
  font-family: 'Saling Cinta';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
}

.button2 {
  font-family: Playfair Display;
  font-style: normal;
  border: unset;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  color: $main-green;
  ;
  background: #fff;
  box-shadow: 0px 8px 12px rgba(90, 90, 90, 0.25);
  padding: 15px 30px;
}

.button2:hover {
  cursor: pointer;
  background: $main-green;
  color: #fff;
  text-decoration: unset;
}

footer {
  padding: 40px 0px;
}

footer p,
footer li,
footer li a {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #5A5A5A;
  margin-bottom: 0px;
}

footer .logo {
  margin-bottom: 30px;
}

footer ul {
  padding: 0px;
  margin: 0px;
}

footer li {
  list-style: none;
}

footer p i {
  color: $main-green;
  margin-right: 10px;
}

.pics img {
  height: 40px;
  margin-right: 10px;
}

.pics {
  padding-top: 30px;
}

div.pad70 {
  padding: 70px 0px
}

@media only screen and (max-width: 768px) {
  div.pad70 {
    padding: 30px 0px
  }
}

@media only screen and (max-width: 600px) {
  div.pad70 {
    padding: 10px 0px
  }
}

.calque5 {
  background-image: url(../img/calque5.png);
  background-size: contain;
  width: 130px;
  height: 94px;
  position: absolute;
  bottom: -52px;
  right: -57px;
  z-index: 999;
  background-repeat: no-repeat;
}

.calque6 {
  background-image: url(../img/calque6.svg);
  background-size: contain;
  width: 203px;
  height: 234px;
  position: absolute;
  bottom: -20px;
  right: 0px;
  z-index: 999;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.object-fit-cover {
  object-fit: cover !important;
}

.box-img6 {
  position: relative;

  &--img {
    background-image: url(../img/img9.jpg);
    background-size: cover;
    width: 100%;
    max-width: 100%;
    height: 500px;
    float: right;
  }
}

.calque7 {
  background-image: url(../img/calque7.svg);
  background-size: contain;
  width: 133px;
  height: 204px;
  position: absolute;
  bottom: -80px;
  right: -100px;
  z-index: 999;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.calque8 {
  background-image: url(../img/bg-green1.svg);
  background-size: 300px 300px;
  background-position: left -60px top 0px;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.h3 {
  font-family: 'Cormorant' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  text-align: center;
}

.calque9 {
  background-image: url(../img/calque8.svg);
  background-size: 300px 300px;
  background-position: left -20px top 30px;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.h1.xl {
  font-family: Cormorant;
  font-size: 100px;
}

.img-box {
  height: 300px;
  margin-bottom: 30px;
}

.calque10 {
  background-image: url(../img/calque9.svg);
  background-size: contain;
  width: 130px;
  height: 94px;
  position: absolute;
  bottom: -52px;
  left: -57px;
  z-index: 999;
  background-repeat: no-repeat;
}

.calque11 {
  position: relative;
}

.calque11::after {
  content: "";
  background-image: url(../img/calque7.svg);
  background-size: contain;
  width: 133px;
  height: 200px;
  position: absolute;
  bottom: 52px;
  right: 57px;
  z-index: 999;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

h2.l {
  font-size: 50px;
}

ul.liste {
  padding: 0px;
  margin: 0px;
}

ul.liste li {
  list-style: none;
  margin-bottom: 30px;
  position: relative;
}

ul.liste li:before {
  content: "";
  position: absolute;
  left: -46px;
  top: 6px;
  transform: translate;
  width: 30px;
  height: 30px;
  background-image: url(../img/check.svg);
  background-size: contain;
}

.calque12 {
  position: relative;
}

.calque12::after {
  content: "";
  background-image: url(../img/calque102.png);
  background-size: contain;
  width: 160px;
  height: 300px;
  position: absolute;
  bottom: 52px;
  left: -50px;
  z-index: 999;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.form-control {
  border: unset;
  box-shadow: unset;
  border-radius: 0px;
  border-bottom: 3px solid #969D79;
}

.gals1 {
  position: relative;
  padding-bottom: 130px !important;
}

.flower1 {
  position: absolute;
  background-image: url(../img/flower1.svg);
  width: 198.86px;
  height: 363.02px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  transform: rotate(-4.41deg);

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower2 {
  position: absolute;
  background-image: url(../img/calque7.svg);
  width: 133.86px;
  height: 304.02px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 90px;
  top: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower3 {
  position: absolute;
  background-image: url(../img/flower3.svg);
  width: 133.86px;
  height: 304.02px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 90px;
  bottom: -100px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower4 {
  position: absolute;
  background-image: url(../img/flower3.svg);
  width: 133.86px;
  height: 304.02px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 90px;
  bottom: -100px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower5 {
  position: absolute;
  background-image: url(../img/flower5.svg);
  width: 133.86px;
  height: 304.02px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 10%;
  bottom: -100px;
}

.file label {
  color: $main-green;
  text-decoration: underline;
  cursor: pointer;
}

.inputs input {
  display: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #969d79 !important;
}

.flower6 {
  position: absolute;
  background-image: url(../img/calque11.svg);
  width: 133.86px;
  height: 304.02px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 40px;
  bottom: -150px;
  z-index: 99;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.relativePos {
  position: relative;
}

.flower7 {
  position: absolute;
  background-image: url(../img/calque10.png);
  width: 205.86px;
  height: 335.02px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 16%;
  bottom: 0px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower8 {
  position: absolute;
  background-image: url(../img/flower5.png);
  width: 200.86px;
  height: 290.02px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 28%;
  bottom: -20px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower9 {
  position: absolute;
  background-image: url(../img/calque12.svg);
  width: 123px;
  height: 216px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 29%;
  bottom: -20px;
}

.infos-btns .button1 {
  min-height: 78px;
  width: 100%;
  line-height: 41px;
}

.flower10 {
  position: absolute;
  background-image: url(../img/calque7.svg);
  width: 123px;
  height: 303px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 5%;
  bottom: -20px;
}

.flower11 {
  position: absolute;
  background-image: url(../img/flower1.svg);
  width: 123px;
  height: 303px;
  background-size: contain;
  background-repeat: no-repeat;
  left: -5%;
  top: -5%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.flower12 {
  position: absolute;
  background-image: url(../img/flower1.svg);
  width: 123px;
  height: 303px;
  background-size: contain;
  background-repeat: no-repeat;
  right: -5%;
  top: -5%;
}

.flower13 {
  position: absolute;
  background-image: url(../img/flower6.svg);
  width: 123px;
  height: 303px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0px;
  top: 5%;
  transform: rotate(-4.41deg);

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.sticky-btn {
  position: fixed;
  left: 20px;
  bottom: 225px;
  z-index: 99;
  border-radius: 10px;
}

#fixphone {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background: #DBB9B4;
  border: 2px solid #DBB9B4;
  color: #fff;
  font-size: 16px;
  padding: 7px 15px;
  border-radius: 10px;
  transition: 0.3s;
  text-decoration: none;
}

#fixphone:hover {
  background: rgba(0, 0, 0, 0);
  color: #DBB9B4;
  text-decoration: none;
}

.bsPFkV {

  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

.fixmenu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.h22 {
  font-family: 'Saling Cinta';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  color: #5A5A5A;
}

.pad15 {
  padding: 15px 0px;
}

.pad50 {
  padding: 50px 0px;
}

.dropdown-toggle::after {
  position: relative;
  top: 3px;
}

.hover4 {
  width: 123px;
  height: 189px;
  transform: rotate(37.78deg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/hover4.png);
  position: absolute;
  top: 0;
  right: 0;
}

.hover5 {
  height: 378px;
  width: 200px;
  background-image: url(../img/hover5.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(-14.66deg);
  position: absolute;
  right: 10px;
  top: 0;

  @media only screen and (max-width: 768px) {
    height: 170px;
    width: 120px;
  }
}

.CormorantFont {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}

.stars span {
  display: inline-block;
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;

  color: #969D79;


}

.stars img {
  display: inline-block;
  width: 130px !important;
  height: auto !important;
}

p.name {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 0px;
  color: #969D79;
}

p.date {
  font-family: 'Cormorant';
  font-style: italic;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  align-items: center;
  margin-bottom: 0px;

  color: #5A5A5A;
}

.boxavis {
  border: 1px solid #969D79;
  padding: 20px;
  margin-bottom: 20px;
}

footer a:hover {
  color: $main-green;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.banner {

  &_content {

    &-links {
      display: flex;
      margin-top: 20px;
      z-index: 1000;

      &--link {
        font-family: 'Cormorant';
        font-size: 16px;
        line-height: 1.1;
        font-weight: 700;
        color: #777;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        padding-bottom: 0;
      }

      &--active {
        color: #969D79;
        transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: none;
          color: #777;
          cursor: pointer;
        }
      }
    }
  }
}


@media(max-width:768px) {
  .calque1 {
    right: 0px;
  }

  .calque4 {
    right: 0px;
  }

  .calque5 {
    right: 0px;
  }

  section.slider h1 {
    color: #5A5A5A;
  }

  .calque7 {
    right: 0px;
  }

  .box-img4 {
    height: 200px;
  }

  footer {
    padding-bottom: 190px;
  }

  section.flowers {
    height: auto;
  }

  .moveTop {
    margin-top: unset;
  }
}

.tohide-768 {

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.pad50 {

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
}

.padding-20 {

  @media only screen and (max-width: 768px) {
    padding: 20px 10px;
  }
}

.padding-40 {

  @media only screen and (max-width: 768px) {
    padding: 40px 10px;
  }
}

.box-img4 {
  @media only screen and (max-width: 768px) {
    margin: 20px 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .animate__animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

.h40 {
  height: 40px !important;
  width: auto !important;
  max-width: auto !important;
}

.active-clr {
  color: #DBB9B4 !important;
}

.w-auto {
  width: auto !important;
  max-width: auto !important;
}

.h-auto {
  @media only screen and (max-width: 768px) {
    height: auto !important;
  }
}

.footlogo {
  object-fit: contain;
  height: 100px !important;
}

.navlogo {
  display: inline !important;
  height: 100px !important;

  @media only screen and (max-width: 600px) {
    width: auto !important;
    max-width: auto !important;
  }
}

.coloredPink {
  color: #DBB9B4 !important;

  @media only screen and (max-width: 768px) {
    color: #5A5A5A !important;
  }
}

.nopad {

  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.box-img5 {

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}

.page4-2img {
  background-image: url("/assets/img/page4-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 100%;
}

.page5-2img {
  background-image: url("/assets/img/page5-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 100%;
}

.newfont {
  font-family: 'Saling Cinta' !important;
}

// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item {
    color: $body-color !important;

    &.active {
      color: $white !important;
    }
  }
}

.pj-wrapper {
  position: fixed;
  left: 0;
  bottom: 100px;
  margin: 20px;
  padding: 12px 10px;
  width: 180px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: #89AA8C;

  @media(max-width: 400px) {
    width: 130px;
  }

  p {
    margin: 0;
  }

  .pj-text,
  .pj-rate {
    color: white;
    font-size: 18px;
    text-align: center;

    @media(max-width: 400px) {
      font-size: 13px;
    }
  }

  .pj-rate {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    svg {
      width: 22px;
      height: 22px;
    }

    @media(max-width: 400px) {
      font-size: 15px;
      svg {
        width: 15px;
        height: 15px;
      }
    }

    span {
      margin-right: 10px;
    }
  }
}